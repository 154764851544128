<template>
  <div class="misc-wrapper">
    <div class="misc-inner p-2 p-sm-3">
      <b-card class="card text-center">
        <div>
          <h1 class="header">
            <span style="color: white">FULFILL</span>
            <span style="color: #ff8c00;">IN</span>
          </h1>
          <h2
            class="mb-1"
            style="color: white; font-family: 'Barlow', sans-serif;"
          >
            Page Not Found 🕵🏻‍♀️
          </h2>
          <p
            class="mb-2"
            style="color: white; font-family: 'Open Sans', sans-serif;"
          >
            Oops! 😖 The requested URL was not found on this server.
          </p>

          <li class="link">
            <router-link
              class="link"
              :to="{ name: 'home' }"
            >
              Home
            </router-link>
          </li>
          <li class="link">
            <router-link
              class="link"
              :to="{ name: 'help' }"
            >
              Help
            </router-link>
          </li>
          <li class="link">
            <router-link
              class="link"
              :to="{ name: 'documentation', params: { pageProp: 'general-contact-us' } }"
            >
              Documentation
            </router-link>
          </li>
          <b-img
            fluid
            :src="imgUrl"
            alt="Error page"
          />
        </div>
      </b-card>
    </div>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { BImg, BCard } from 'bootstrap-vue'
import store from '@/store/index'

export default {
  components: {
    BImg,
    BCard,
  },
  data() {
    return {
      downImg: require('@/assets/images/pages/error.svg'),
    }
  },
  computed: {
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.downImg = require('@/assets/images/pages/error-dark.svg')
        return this.downImg
      }
      return this.downImg
    },
  },
}
</script>

<style lang="scss" scoped>
@import '../../src/@core/scss/vue/pages/page-misc.scss';

.misc-wrapper {
  background-image: url("../assets/images/background.png");
  background-position: center;
  background-attachment: fixed;
  background-size: cover;
}

.link {
  color: white;
  text-decoration: none;
  list-style: none;
  font-size: 18px;
  text-align: center;
  font-family: 'Open Sans', sans-serif;
  &:hover {
    color: darkorange;
    transition: color .3s ease;
  }
}

.card {
  background-color: #1b1c1e;
  box-shadow: black 0 0 20px;
}

.header {
  font-family: 'Barlow', sans-serif;
}
</style>
